<template>
	<a target="_blank" rel="noopener noreferrer" :href="getUrl({ ...props })">
		<slot />
	</a>
</template>

<script setup>
import { BookingEngineFactory } from '@becurious/mews';

const { locale } = useI18n();
const bookingEngine = useState('bookingEngine', () => {});

const props = defineProps({
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	promo: { type: String, default: '' },
	roomID: { type: String, default: '' },
	language: { type: String, default: '' },
});

onMounted(() => {
	if (!bookingEngine.value) {
		const factory = new BookingEngineFactory({
			hotelID: '4400d173-f2ca-45b2-a8a3-af9000a99765',
			mode: 'standalone',
		});

		bookingEngine.value = factory.createBookingEngine();
	}
});

const getUrl = (options) => {
	if (!bookingEngine.value) {
		return null;
	}

	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	return bookingEngine.value.getUrl(options);
};
</script>

<style lang="scss" scoped>
a {
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
